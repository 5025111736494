import { createRouter, createWebHashHistory } from "vue-router";
const router = createRouter({
  history: createWebHashHistory(),
  routes: [
    {
      path: "/",
      name: "login",
      component: () => import("../components/login.vue"),
    },
    {
      path: "/home",
      redirect: "/launch",
      meta: { auth: true },
      component: () => import("../components/home.vue"),
      children: [
        {
          path: "/version",
          meta: { auth: true },
          component: () => import("../components/version.vue"),
        },
        {
          path: "/url",
          meta: { auth: true },
          component: () => import("../components/url.vue"),
        },
        {
          path: "/banner",
          meta: { auth: true },
          component: () => import("../components/banner.vue"),
        },
        {
          path: "/notify",
          meta: { auth: true },
          component: () => import("../components/notify.vue"),
        },
        {
          path: "/pkg",
          meta: { auth: true },
          component: () => import("../components/pkg.vue"),
        },
        {
          path: "/cpa",
          meta: { auth: true },
          component: () => import("../components/cpa.vue"),
        },
        {
          path: "/token",
          meta: { auth: true },
          component: () => import("../components/token.vue"),
        },
        {
          path: "/host",
          meta: { auth: true },
          component: () => import("../components/host.vue"),
        },
        {
          path: "/module",
          meta: { auth: true },
          component: () => import("../components/module.vue"),
        },
        {
          path: "/girl",
          meta: { auth: true },
          component: () => import("../components/girl.vue"),
        },
        {
          path: "/launch",
          meta: { auth: true },
          component: () => import("../components/launch.vue"),
        },
      ],
    },
  ],
});

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.auth)) {
    const token = localStorage.getItem("token");
    if (token && token.trim() !== "") {
      next();
    } else {
      next({ name: "login" });
    }
  } else {
    next();
  }
});

export default router;
